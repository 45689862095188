<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-row>
          <b-col class="mt-3 pt-2">
            <h4 class="page-title">Store Details</h4>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-name" label-for="input-name">
                  <template v-slot:label>
                    Store Name
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    :formatter="formatter"
                    :space="space"
                    id="input-name"
                    type="text"
                    v-model="form.storeName"
                    placeholder="Enter Store Name"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-name-invalid">Please enter Store Name.</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group v-if="ifAdmin()" id="group-company" label-for="input-company">
                      <template v-slot:label> Company </template>

                      <multiselect
                        :class="{
                          'box-shadow3': !isDarkMode,
                          darkMode: isDarkMode
                        }"
                        id="input-frequency"
                        :options="['SRL', 'NBL']"
                        v-model="form.company"
                        placeholder="Select Company"
                      >
                      </multiselect>
                    </b-form-group>
                    <b-form-group v-if="ifNBUser()" id="group-company" label-for="input-company">
                      <template v-slot:label> Company </template>

                      <multiselect
                        :class="{
                          'box-shadow3': !isDarkMode,
                          darkMode: isDarkMode
                        }"
                        id="input-frequency"
                        :options="['NBL']"
                        v-model="form.company"
                        placeholder="Select Company"
                      >
                      </multiselect>
                    </b-form-group>
                    <b-form-group v-if="ifSRLUser()" id="group-company" label-for="input-company">
                      <template v-slot:label> Company </template>

                      <multiselect
                        :class="{
                          'box-shadow3': !isDarkMode,
                          darkMode: isDarkMode
                        }"
                        id="input-frequency"
                        :options="['SRL']"
                        v-model="form.company"
                        placeholder="Select Company"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-if="ifAdmin()" id="group-company-petty" label-for="input-company-petty">
                      <template v-slot:label> Petty Cash Company Name </template>

                      <multiselect
                        :class="{
                          'box-shadow3': !isDarkMode,
                          darkMode: isDarkMode
                        }"
                        id="input-frequency"
                        :options="['SRL', 'NBL', 'CESC']"
                        v-model="form.pettyCashCompanyName"
                        placeholder="Select Company"
                      >
                      </multiselect> </b-form-group
                  ></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-products" label-for="input-products">
                  <template v-slot:label>
                    Format
                    <span class="text-danger">*</span>
                  </template>
                  <multiselect
                    :class="{
                      'box-shadow3': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                    v-model="form.format"
                    ref="multiselect"
                    tag-placeholder="Add this as new tag"
                    label="text"
                    track-by="text"
                    :options="formats"
                    :multiple="false"
                    :taggable="true"
                    @tag="addTag"
                  ></multiselect>
                  <b-form-text id="input-role-help">Please select the store format.</b-form-text>
                </b-form-group>
              </b-col>
              <b-col>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-name" label-for="input-name">
                      <template v-slot:label>
                        Store Id
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-name"
                        type="text"
                        v-model="form.storeid"
                        placeholder="Enter Store Id "
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-name-invalid">Please enter Store Id.</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="group-products" label-for="input-products">
                      <template v-slot:label>
                        Company Code
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-name"
                        type="number"
                        v-model="form.companyCode"
                        placeholder="Enter Company Code"
                      ></b-form-input>
                      <b-form-text id="input-role-help">Please enter the Company Code.</b-form-text>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-products" label-for="input-products">
                  <template v-slot:label>
                    Cluster
                    <span class="text-danger">*</span>
                  </template>
                  <multiselect
                    :class="{
                      'box-shadow3': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                    v-model="form.cluster"
                    ref="multiselect"
                    tag-placeholder="Add this as new tag"
                    label="text"
                    track-by="text"
                    :options="clusterIds"
                    :multiple="false"
                    :taggable="true"
                    @tag="addTag"
                  ></multiselect>
                  <b-form-text id="input-role-help">Please select the cluster where store is located.</b-form-text>
                </b-form-group>
              </b-col>
              <b-col>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-products" label-for="input-products">
                      <template v-slot:label>
                        Cost Center
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        :formatter="formatter"
                        id="input-name"
                        type="text"
                        v-model="form.costCenter"
                        placeholder="Enter Cost Center "
                      ></b-form-input>
                      <b-form-text id="input-role-help">Please enter the Cost Center.</b-form-text>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="group-products" label-for="input-products">
                      <template v-slot:label>
                        Petty Cash Store GL
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-name"
                        type="number"
                        v-model="form.pattyCashStoreGl"
                        placeholder="Enter Petty Cash Store GL "
                      ></b-form-input>
                      <b-form-text id="input-role-help">Please enter the Petty Cash Store GL.</b-form-text>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-products" label-for="input-products">
                  <template v-slot:label>
                    Region
                    <span class="text-danger">*</span>
                  </template>
                  <multiselect
                    :class="{
                      'box-shadow3': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                    v-model="form.region"
                    ref="multiselect"
                    tag-placeholder="Add this as new tag"
                    label="text"
                    track-by="text"
                    :options="regionIds"
                    :multiple="false"
                    :taggable="true"
                    @tag="addTag"
                  ></multiselect>
                  <b-form-text id="input-role-help">Please select the region where store is located.</b-form-text>
                </b-form-group>
              </b-col>
              <b-col>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-name" label-for="input-city">
                      <template v-slot:label>
                        City
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-city"
                        type="text"
                        v-model="form.city"
                        placeholder="Enter City"
                      ></b-form-input> </b-form-group
                  ></b-col>
                  <b-col>
                    <b-form-group id="group-tradingArea" label-for="input-tradingArea">
                      <template v-slot:label>
                        Trading Area
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-tradingArea"
                        type="text"
                        v-model="form.tradingArea"
                        placeholder="Enter Trading Area"
                      ></b-form-input> </b-form-group
                  ></b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3 pt-2">
          <b-col>
            <h4 class="page-title">Roles Details</h4>
            <b-row>
              <b-col sm="12" lg="6">
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-rsmName" label-for="input-rsmName">
                      <template v-slot:label>
                        RSM Name
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-rsmName"
                        type="text"
                        v-model="form.rsmName"
                        placeholder="Enter RSM Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="group-rsmMobile" label-for="input-rsmMobile">
                      <template v-slot:label>
                        RSM mobile
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-rsmMobile"
                        :formatter="numFormatters"
                        type="text"
                        v-model="form.rsmMobile"
                        placeholder="Enter RSM Mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-form-group id="group-rsmEmail" label-for="input-rsmEmail">
                  <template v-slot:label>
                    RSM Email
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-asmEmail"
                    type="text"
                    v-model="form.rsmEmail"
                    placeholder="Enter RSM Email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="group-businessManagerEmail" label-for="input-businessManagerEmail">
                  <template v-slot:label>
                    Business Manager Email
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-asmEmail"
                    type="text"
                    v-model="form.businessManagerEmail"
                    placeholder="Enter Business Manager Email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="6">
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-asmName" label-for="input-asmName">
                      <template v-slot:label>
                        ASM Name
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-asmName"
                        type="text"
                        v-model="form.asmName"
                        placeholder="Enter ASM Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="group-asmMobile" label-for="input-asmMobile">
                      <template v-slot:label>
                        ASM mobile
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-asmMobile"
                        type="text"
                        v-model="form.asmMobile"
                        :formatter="numFormatters"
                        placeholder="Enter ASM Mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-form-group id="group-asmEmail" label-for="input-asmEmail">
                  <template v-slot:label>
                    ASM Email
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-asmEmail"
                    type="text"
                    v-model="form.asmEmail"
                    placeholder="Enter ASM Email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="6">
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-regionalAuditorEmail" label-for="input-regionalAuditorEmail">
                      <template v-slot:label>
                        Regional Auditor Email
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-regionalAuditorEmail"
                        type="text"
                        v-model="form.regionalAuditorEmail"
                        placeholder="Enter Regional Auditor Email"
                      ></b-form-input> </b-form-group
                  ></b-col>
                  <b-col>
                    <b-form-group id="group-regionalQualityManagerEmail" label-for="input-regionalQualityManagerEmail">
                      <template v-slot:label>
                        Regional Quality Manager Email
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-regionalQualityManagerEmail"
                        type="text"
                        v-model="form.regionalQualityManagerEmail"
                        placeholder="Enter Regional Quality Manager Email"
                      ></b-form-input> </b-form-group
                  ></b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-form-group id="group-regionalLpManagerEmail" label-for="input-regionalLpManagerEmail">
                  <template v-slot:label>
                    Regional LP Manager Email
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-regionalLpManagerEmail"
                    type="text"
                    v-model="form.regionalLpManagerEmail"
                    placeholder="Enter Regional LP Manager Email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="6"
                ><b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="group-storeManagerName" label-for="input-storeManagerName">
                      <template v-slot:label>
                        SM Name
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-storeManagerName"
                        type="text"
                        v-model="form.storeManagerName"
                        placeholder="Enter Store Manager Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="group-storeManagerMobile" label-for="input-storeManagerMobile">
                      <template v-slot:label>
                        SM mobile
                        <span class="text-danger">*</span>
                      </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-storeManagerMobile"
                        type="text"
                        v-model="form.storeManagerMobile"
                        :formatter="numFormatters"
                        placeholder="Enter Store Manager mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-form-group id="group-products" label-for="input-products">
                  <template v-slot:label>
                    SM Email
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-name"
                    type="email"
                    v-model="form.storeManagerEmail"
                    placeholder="Enter Store Manager Email"
                  ></b-form-input>
                  <b-form-text id="input-role-help"></b-form-text>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="6"> </b-col>
              <b-col> </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-form-group>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button
                class="box-shadow2"
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button
                class="ml-2 box-shadow2"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/stores` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Role from '@/model/role';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapState } from 'vuex';
import router from '@/router';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
  name: 'StoreFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    roleType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      formLoaded: false,
      regionIds: [],
      clusterIds: [],
      formats: [],
      minDate: moment('2015-01-01').format('YYYY-MM-DD'),
      form: {
        storeName: '',
        storeid: '',
        cluster: null,
        region: null,
        format: null,
        companyCode: '',
        storeManagerEmail: '',
        costCenter: '',
        pattyCashStoreGl: '',
        asmMobile: '',
        asmName: '',
        city: '',
        rsmMobile: '',
        company: '',
        pettyCashCompanyName: '',
        rsmName: '',
        rsmEmail: '',
        storeManagerMobile: '',
        storeManagerName: '',
        asmEmail: '',
        businessManagerEmail: '',
        regionalAuditorEmail: '',
        regionalQualityManagerEmail: '',
        regionalLpManagerEmail: '',
        tradingArea: ''
      }
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      permission: {
        required
      },
      company: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.permission.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        if (this.roleType === 'role') {
          this.form.id = Role.role.id;
        }
        this.form.status = Role.roleEnabled;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new store' : 'Update Store';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('user', ['loading', 'user', 'availableRoles']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList', 'store']),
    ...mapState('auth', {
      authUser: 'user'
    })
  },
  methods: {
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),
    ...mapActions('user', ['getRoles']),
    ifAdmin() {
      const newUser = this.authUser.roles.filter(role => role.name === 'ADMIN');
      if (newUser.length > 0) {
        return true;
      }
      return false;
    },
    ifNBUser() {
      const newUser = this.authUser.company === 'NBL';
      if (newUser) {
        return true;
      }
      return false;
    },
    ifSRLUser() {
      const newUser =
        this.authUser.company === 'SRL' && this.authUser.roles.filter(role => role.name === 'ADMIN').length === 0;
      if (newUser) {
        return true;
      }
      return false;
    },

    formatter(name) {
      return name.toUpperCase();
    },
    numFormatters(text) {
      return text.substring(0, 10);
    },
    space(name) {
      return name.split(' ').join('_');
    },

    onSubmit() {
      if (
        !this.form.storeName ||
        !this.form.storeid ||
        !this.form.cluster.value ||
        !this.form.region.value ||
        !this.form.format.value ||
        !this.form.company ||
        !this.form.city ||
        !this.form.tradingArea
      ) {
        Vue.swal({
          title: 'Some Fields/Field are Empty',
          icon: 'error',
          text: `Please provide all Information`,
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return false;
      }
      const store = {
        storeName: this.form.storeName,
        storeid: this.form.storeid,
        cluster: this.form.cluster.value,
        region: this.form.region.value,
        format: this.form.format.value,
        companyCode: this.form.companyCode,
        storeManagerEmail: this.form.storeManagerEmail,
        costCenter: this.form.costCenter,
        pattyCashStoreGl: this.form.pattyCashStoreGl,
        asmMobile: this.form.asmMobile,
        company: this.form.company,
        pettyCashCompanyName: this.form.pettyCashCompanyName,
        asmName: this.form.asmName,
        city: this.form.city,
        rsmMobile: this.form.rsmMobile,
        rsmName: this.form.rsmName,
        storeManagerMobile: this.form.storeManagerMobile,
        storeManagerName: this.form.storeManagerName,
        asmEmail: this.form.asmEmail,
        businessManagerEmail: this.form.businessManagerEmail,
        rsmEmail: this.form.rsmEmail,
        regionalAuditorEmail: this.form.regionalAuditorEmail,
        regionalQualityManagerEmail: this.form.regionalQualityManagerEmail,
        regionalLpManagerEmail: this.form.regionalLpManagerEmail,
        tradingArea: this.form.tradingArea
      };
      if (this.formType === 'new') {
        this.$emit('add', { store });
      } else {
        this.$emit('edit', { store });
      }
      return false;
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    clusterList(newValue) {
      this.clusterIds = newValue;
    },
    regionList(newValue) {
      this.regionIds = newValue;
    },
    formatList(newValue) {
      this.formats = newValue;
    },
    store(_newValue, _oldValue) {
      const {
        cluster,
        region,
        format,
        companyCode,
        storeManagerEmail,
        costCenter,
        pattyCashStoreGl,
        storeName,
        storeid,
        asmMobile,
        asmName,
        city,
        rsmMobile,
        rsmName,
        company,
        pettyCashCompanyName,
        storeManagerMobile,
        storeManagerName,
        asmEmail,
        businessManagerEmail,
        rsmEmail,
        regionalAuditorEmail,
        regionalQualityManagerEmail,
        regionalLpManagerEmail,
        tradingArea
      } = this.store;
      this.form.storeName = storeName;
      this.form.storeid = storeid;
      this.form.cluster = { text: cluster, value: cluster };
      this.form.region = { text: region, value: region };
      this.form.format = { text: format, value: format };
      this.form.companyCode = companyCode;
      this.form.storeManagerEmail = storeManagerEmail;
      this.form.costCenter = costCenter;
      this.form.pattyCashStoreGl = pattyCashStoreGl;
      this.formLoaded = true;
      this.form.asmMobile = asmMobile;
      this.form.company = company;
      this.form.pettyCashCompanyName = pettyCashCompanyName;
      this.form.asmName = asmName;
      this.form.city = city;
      this.form.rsmMobile = rsmMobile;
      this.form.rsmName = rsmName;
      this.form.storeManagerMobile = storeManagerMobile;
      this.form.storeManagerName = storeManagerName;
      this.form.asmEmail = asmEmail;
      this.form.businessManagerEmail = businessManagerEmail;
      this.form.rsmEmail = rsmEmail;
      this.form.regionalAuditorEmail = regionalAuditorEmail;
      this.form.regionalQualityManagerEmail = regionalQualityManagerEmail;
      this.form.regionalLpManagerEmail = regionalLpManagerEmail;
      this.form.tradingArea = tradingArea;
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
