<template>
  <div class="component-tablebox">
    <promo-template-a4 v-show="false" :data="templateDataA4" ref="promoTemplateA4"></promo-template-a4>
    <promo-template-a7 v-show="false" :data="templateDataA7" ref="promoTemplateA7"></promo-template-a7>
    <b-row v-if="showToolbar" class="table-top-wrapper mb-2 mx-0">
      <b-col sm="12" lg="8" class="px-0">
        <b-form inline @submit.prevent>
          <b-input-group data-v-step="0" class="box-shadow2 ml-2 mb-2">
            <b-form-input
              :class="{
                'bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              v-model="searchTerm"
              @keydown.native="search"
              placeholder="Search..."
            ></b-form-input>
            <template #append v-if="searchItems">
              <b-dropdown
                :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
                :text="selectedSearchVariable"
                :variant="isDarkMode ? 'primary' : 'success'"
              >
                <b-dropdown-item
                  :link-class="isDarkMode ? 'text-light bg-dark' : ''"
                  v-for="item in searchItems"
                  :key="item"
                  @click="selectedSearchVariable = item"
                  >{{ item }}</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-input-group>
          <b-form-select
            data-v-step="1"
            v-model="filterSelected"
            :options="filterOptions"
            :class="{
              'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>
          <b-form-select
            v-model="filterSelectedForType"
            :options="filterOptionsForType"
            :class="{
              'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>
          <b-form-select
            data-v-step="1"
            v-model="filterSelectedForSoh"
            :options="filterOptionsForSoh"
            :class="{
              'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>
          <b-input-group>
            <multiselect
              v-model="filterSelectedForFamily"
              :options="filterOptionsForFamily"
              class="box-shadow2 ml-2 mb-2"
              placeholder="Search Family Type"
              :class="{
                'box-shadow3': !isDarkMode,
                darkMode: isDarkMode
              }"
            ></multiselect>
          </b-input-group>
        </b-form>
      </b-col>

      <b-col sm="12" lg="4">
        <b-form data-v-step="2" inline class="float-right">
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="start-datepicker"
                placeholder="Start Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              sm
              id="example-input"
              v-model="startDateValue"
              type="text"
              placeholder="Start Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-button
            :class="{
              'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
              'btn btn-primary text-light ml-2 mb-2': isDarkMode
            }"
            @click="applyDate"
            >Apply</b-button
          >
          <b-button
            :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
            class="box-shadow2 ml-2 mb-2"
            @click="clearDate"
            >Clear</b-button
          >
        </b-form>
      </b-col>
    </b-row>

    <!-- download buttons -->
    <b-row class="table-bottom-wrapper mb-3 mx-0 p-2 columnsReverse">
      <b-col sm="12" lg="8" class="px-0 d-flex align-items-center">
        <b-row>
          <b-col sm="12" lg="12">
            <input
              :checked="allSelected"
              :disabled="isDisabled"
              @click="selectAll"
              class="allimage"
              type="checkbox"
              id="scales"
            />
            <label for="scales" class="px-2">Select all </label>
            <b-button
              data-v-step="3"
              :disabled="this.selectedArticles.length < 1"
              v-if="showPrint"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="printAll('A4')"
            >
              <font-awesome-icon :icon="['fas', 'clone']" class="mr-1" />{{ printTextA4 }}
            </b-button>
            <b-button
              data-v-step="3"
              :disabled="this.selectedArticles.length < 1"
              v-if="showPrint"
              v-b-modal.modal-lg
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="printAll('A7')"
            >
              <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />{{ printTextA7 }}
            </b-button>
            <b-modal
              :header-bg-variant="isDarkMode ? 'dark' : 'light'"
              :header-text-variant="isDarkMode ? 'light' : 'dark'"
              :body-bg-variant="isDarkMode ? 'dark' : 'light'"
              :footer-bg-variant="isDarkMode ? 'dark' : 'light'"
              :body-text-variant="isDarkMode ? 'light' : 'dark'"
              id="modal-lg"
              size="lg"
              v-model="modalVisible"
              title="Enter number of copy per item"
            >
              <div v-for="(array, i) in inputSets" :key="i">
                <b-row class="my-2">
                  <b-col lg="9" sm="12">
                    <input
                      type="text"
                      :class="{
                        'form-control bg-light text-dark': !isDarkMode,
                        'form-control bg-dark text-light placeholder-light': isDarkMode
                      }"
                      disabled
                      :value="array.articleDescription"
                      :placeholder="array.articleDescription"
                  /></b-col>
                  <b-col lg="2" sm="12">
                    <input
                      type="number"
                      min="1"
                      max="3"
                      onkeyup="if(this.value > 3) this.value = 1;"
                      :class="{
                        'form-control inputValues bg-light text-dark': !isDarkMode,
                        'form-control inputValues bg-dark text-light placeholder-light': isDarkMode
                      }"
                      placeholder="1"
                  /></b-col>
                  <b-col lg="1" sm="12">
                    <button
                      @click="removeArticle(i)"
                      :class="{ 'btn btn-danger': !isDarkMode, 'btn btn-outline-danger': isDarkMode }"
                      >X</button
                    ></b-col
                  >
                </b-row>
              </div>
              <template #modal-footer>
                <button
                  @click="clickModalPrintA7()"
                  :class="{
                    'box-shadow2 btn btn-success': !isDarkMode,
                    'btn btn-primary text-light': isDarkMode
                  }"
                  >Print</button
                >
                <button
                  @click="modalVisible = false"
                  :class="{
                    'box-shadow2 btn btn-danger': !isDarkMode,
                    'btn btn-outline-danger text-light': isDarkMode
                  }"
                  >Cancel</button
                >
              </template>
            </b-modal>

            <b-button
              data-v-step="3"
              v-if="showPrint"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="clickAdd"
            >
              <font-awesome-icon :icon="['fas', 'palette']" class="mr-1" />{{ manualText }}
            </b-button>
            <b-button
              data-v-step="4"
              v-if="showPrint"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="clickDownload"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }} {{ appendedText }}
            </b-button>

            <b-button
              data-v-step="4"
              v-if="showPromo && ifAdmin()"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="openModal"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadPromoText }}
            </b-button>

            <b-modal v-model="modalOpen" hide-footer title="Select Date Range" size="sm">
              <template>
                <div>
                  <div>
                    <h6>Start Date</h6>
                    <b-form data-v-step="2" inline class="float-right">
                      <b-input-group class="box-shadow2 ml-2 mb-2">
                        <b-input-group-append>
                          <b-form-datepicker
                            button-only
                            id="start-datepicker"
                            placeholder="Start Date"
                            :min="minDate"
                            :max="maxDate"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="startDateValuePromo"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <b-form-input
                          id="example-input"
                          v-model="startDateValuePromo"
                          type="text"
                          placeholder="Start Date"
                          autocomplete="off"
                          :class="{
                            '': !isDarkMode,
                            'bg-dark text-light placeholder-light': isDarkMode
                          }"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                      <div>
                        <h6>End Date</h6>
                        <b-input-group class="box-shadow2 ml-2 mb-2">
                          <b-input-group-append>
                            <b-form-datepicker
                              button-only
                              id="end-datepicker"
                              placeholder="End Date"
                              :min="minDate"
                              :max="maxDate"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              v-model="endDateValue"
                            ></b-form-datepicker>
                          </b-input-group-append>
                          <b-form-input
                            id="example-input"
                            v-model="endDateValue"
                            type="text"
                            placeholder="End Date"
                            autocomplete="off"
                            :class="{
                              '': !isDarkMode,
                              'bg-dark text-light placeholder-light': isDarkMode
                            }"
                            disabled
                          ></b-form-input>
                        </b-input-group>
                        <b-button
                          :class="{
                            'box-shadow2 btn btn-success ml-3 mb-2 mt-3': !isDarkMode,
                            'btn btn-primary text-light ml-2 mb-2': isDarkMode
                          }"
                          @click="applyDatePromo"
                          >Download</b-button
                        >
                        <b-button
                          :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                          class="box-shadow2 ml-5 mb-2 mt-3"
                          @click="clearDatePromo"
                          >Clear</b-button
                        >

                        <div class="text-right"> </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </template>
            </b-modal>

            <div v-if="downloadLoading" class="ml-2">
              <span :class="{ 'spinner spinner-black': !isDarkMode, 'spinner spinner-white': isDarkMode }"></span>
            </div>
          </b-col>
          <b-col
            ><div class="table-top-wrapper d-flex">
              <div v-if="totalCount" class="row-total-count mr-3"
                ><span>Select Count :</span> {{ selectedArticleCount }},</div
              >
              <div
                v-if="printedCount"
                :class="{ 'table-isPrinted': !isDarkMode, 'table-isPrinted-dark': isDarkMode }"
                class="row-total-count px-2 py-5 rounded mr-3"
              >
                <span>Page Print Count:</span>
                {{ printedCount }},
              </div>
              <div v-if="totalCount" class="row-total-count">
                <span>Total Count:</span>
                {{ totalCount }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col sm="12" lg="4" class="px-0 text-right">
        <div class="d-flex flex-column">
          <h4> {{ updateTime ? `Updated at : ${updateTime}` : '' }}</h4>
          <h6>{{ deactivatedPromo ? `Deactivated Promo : ${deactivatedPromo}` : '' }}</h6>
          <h6>{{ expiredPromo ? `Expired Promo : ${expiredPromo}` : '' }} </h6>
          <h6>{{ newPromoLength ? `New Promo : ${newPromoLength}` : '' }} </h6>
          <h6>{{ allPrintedCount ? `Total Printed : ${allPrintedCount}` : '' }}</h6>
          <h6>{{ stockAvailable ? `Total Available : ${stockAvailable}` : '' }}</h6>
        </div>
      </b-col>
    </b-row>

    <!-- total rows -->

    <!-- table content -->
    <div
      :class="{
        'box-shadow2': !isDarkMode,
        '': isDarkMode
      }"
    >
      <div
        :class="{
          'table-row table-row-header': !isDarkMode,
          'table-row-dark dark-div border-top': isDarkMode
        }"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          {{ column.headerText }}
        </div>
      </div>
      <div
        :class="{
          'table-row ': !isDarkMode,
          'table-row-dark': isDarkMode
        }"
        v-for="row in rows"
        :key="row.id"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="[
            {
              ...column.class,
              'table-column ': !isDarkMode,
              'table-column-dark': isDarkMode,
              'table-column-first': index === 0,
              'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1,
              'table-isPrinted': row.isPrinted === true && !isDarkMode,
              'table-isPrinted-dark': row.isPrinted === true && isDarkMode
            }
          ]"
          :style="columnStyle(column)"
        >
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <div v-if="column.urlKey"
            ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
          >

          <div v-if="column.textKey === 'select'">
            <input
              class="checkbox2"
              type="checkbox"
              :disabled="isExpired(row)"
              :checked="selectedArticles.includes(row)"
              @click="select(row)"
            />
          </div>
          <span v-if="column.textKey && column.textKey != 'activeTillTime'" class="span-text">{{
            row[column.textKey]
          }}</span>
          <span v-if="column.textKey === 'activeTillTime'" class="span-text">{{ showTime(row[column.textKey]) }}</span>
          <div v-if="column.type === 'functions'" class="text-center">
            <b-button-group>
              <b-button
                data-v-step="5"
                v-if="column.functions.edit"
                size="sm"
                :disabled="isExpired(row)"
                @click="printSingleArticle(row, 'A4')"
                :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                class="box-shadow2"
              >
                <font-awesome-icon :icon="['fas', 'clone']" class="mr-1" />Print A4 </b-button
              ><br />
              <b-button
                data-v-step="5"
                v-if="column.functions.edit"
                size="sm"
                :disabled="isExpired(row)"
                @click="printSingleArticle(row, 'A7')"
                :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                class="box-shadow2"
              >
                <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />Print A7
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && rows.length === 0"
        :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
      >
        <div
          :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }"
          >{{ emptyText }}</div
        >
      </div>
      <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
        <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
          <div
            :class="{
              'table-column table-column-full text-center': !isDarkMode,
              'table-column-dark table-column-full text-center': isDarkMode
            }"
          >
          </div>
        </div>
      </b-overlay>
    </div>
    <b-row class="table-bottom-wrapper mt-2 mx-0">
      <b-col lg="8" sm="12" class="px-0 d-flex align-items-center">
        <div class="mt-2">
          <input
            :checked="allSelected"
            :disabled="isDisabled"
            @click="selectAll"
            class="allimage"
            type="checkbox"
            id="scales"
          />
          <label for="scales" class="px-2">Select all </label>
        </div>
      </b-col>
      <b-col lg="4" sm="12" v-if="showPagination">
        <b-pagination-nav
          v-if="rows.length"
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalNumberOfPage"
          use-router
          no-page-detect
          align="right"
          :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
        ></b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import PromoTemplateA4 from '@/components/PromoTemplateA4.vue';
import PromoTemplateA7 from '@/components/PromoTemplateA7.vue';
import PrintTemplate from '@/helper/printTemplateHelper';
import Vue from 'vue';
import router from '@/router';
import Multiselect from 'vue-multiselect';

export default {
  name: 'PopBox',
  components: { PromoTemplateA4, PromoTemplateA7, Multiselect },
  props: {
    columns: Array,
    rows: Array,
    pagination: Object,
    baseUrl: String,
    showToolbar: Boolean,
    downloadText: String,
    downloadPromoText: String,
    searchItems: Array,
    showPrint: Boolean,
    showPromo: Boolean,
    emptyText: String,
    filterOptions: Array,
    filterOptionsForSoh: Array,
    filterOptionsForType: Array,
    filterOptionsForFamily: Array,
    printTextA4: String,
    printTextA7: String,
    loading: Boolean,
    downloadLoading: Boolean,
    manualText: String,
    showPagination: Boolean,
    modalTitle: String,
    modalContent: String,
    modalOkButton: String,
    modalCancelButton: String
  },
  data() {
    return {
      appendedText: 'List',
      modalVisible: false,
      inputSets: [],
      filterSelected: null,
      filterSelectedForSoh: null,
      filterSelectedForType: null,
      filterSelectedForFamily: null,
      startDateValue: moment().format('YYYY-MM-DD'),
      startDateValuePromo: moment().format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      selectedArticles: [],
      allSelected: false,
      templateDataA7: [],
      templateDataA4: [],
      isDisabled: false,
      selectedArticleCount: 0,
      field1: '',
      field2: '',
      field3: '',
      arrayOfInputFieldValues: [],
      modalOpen: false
    };
  },
  computed: {
    ...mapState('popPrint', ['popReport']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('auth', ['user']),
    totalCount() {
      if (this.pagination) {
        return this.pagination.total_rows;
      }
      return 0;
    },
    printedCount() {
      if (this.rows.length > 0) {
        return this.rows.filter(item => item.isPrinted === true).length;
      }
      return 0;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    updateTime() {
      if (this.rows) {
        return this.rows[0]?.updatedAt;
      }
      return -1;
    },
    newPromoLength() {
      if (this.popReport) {
        return this.popReport?.newPromoCount;
      }
      return -1;
    },
    deactivatedPromo() {
      if (this.popReport) {
        return this.popReport?.deactiveCount;
      }
      return -1;
    },
    expiredPromo() {
      if (this.popReport) {
        return this.popReport?.expiredCount;
      }
      return -1;
    },
    allPrintedCount() {
      if (this.popReport) {
        return this.popReport?.isPrintedNewPromo;
      }
      return -1;
    },
    stockAvailable() {
      if (this.popReport) {
        return this.popReport?.stockAvailable;
      }
      return -1;
    }
  },
  watch: {
    selectedArticles(newValue) {
      this.selectedArticleCount = newValue.length;
    },
    filterSelected(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selectedArticles = [];
        this.allSelected = false;
        if (newValue !== null) {
          this.appendedText = newValue;
        } else {
          this.appendedText = 'List';
        }
      }
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
      if (newValue === 'DEACTIVATED PROMO' || newValue === 'EXPIRED PROMO') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    filterSelectedForSoh(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter4 !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter4;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter4: newValue } });
        }
      }
    },

    filterSelectedForType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedArticles = [];
        this.allSelected = false;
      }
      if (newValue !== oldValue && this.$route.query.filter2 !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter2;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter2: newValue } });
        }
      }
    },
    filterSelectedForFamily(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selectedArticles = [];
        this.allSelected = false;
      }
      if (newValue !== oldValue && this.$route.query.filter3 !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter3;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter3: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter4')) {
      this.filterSelectedForSoh = this.$route.query.filter4;
    } else {
      this.filterSelectedForSoh = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter2')) {
      this.filterSelectedForType = this.$route.query.filter2;
    } else {
      this.filterSelectedForType = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter3')) {
      this.filterSelectedForFamily = this.$route.query.filter3;
    } else {
      this.filterSelectedForFamily = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startDate')) {
      this.startDateValuePromo = this.$route.query.startDate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'endDate')) {
      this.endDateValue = this.$route.query.endDate;
    }
  },
  methods: {
    ...mapActions('popPrint', ['patchOne']),
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN');
      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    clickAdd() {
      this.$emit('add', {});
    },
    isExpired(row) {
      if (row.delta === 'DEACTIVATED PROMO' || row.delta === 'EXPIRED PROMO') {
        return true;
      }
      return false;
    },
    clickDownload() {
      this.$emit('download', {});
    },
    clickDownloadPromo() {
      this.$emit('download-promo', {});
    },

    applyDatePromo() {
      const promoDate = {
        startdate: moment(this.startDateValuePromo).format('YYYY-MM-DD'),
        enddate: moment(this.endDateValue).format('YYYY-MM-DD')
      };
      this.$emit('download-promo', { ...promoDate });
      this.modalOpen = false;
    },
    clearDatePromo() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startDate')) {
        const query = { ...this.$route.query };
        delete query.startDate;
        delete query.endDate;
        this.$router.push({ query });
      }
      this.startDateValuePromo = null;
      this.endDateValue = null;
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedArticles = [];
        this.allSelected = false;
      } else {
        this.selectedArticles = this.rows.filter(row => row.delta === 'NEW PROMO');
        this.allSelected = true;
      }
    },
    select(row) {
      this.allSelected = false;
      if (this.selectedArticles.includes(row)) {
        this.selectedArticles = this.selectedArticles.filter(i => i !== row);
      } else {
        this.selectedArticles = [...this.selectedArticles, row];
      }
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    removeArticle(index) {
      this.inputSets.splice(index, 1);
    },
    clickModalPrintA7() {
      this.modalVisible = false;
      const inputs = document.getElementsByClassName('inputValues');
      this.arrayOfInputFieldValues = [];
      for (let i = 0; i < inputs.length; i++) {
        if (Number(inputs[i].value) <= 0) {
          this.arrayOfInputFieldValues.push(1);
        } else this.arrayOfInputFieldValues.push(Number(inputs[i].value));
      }
      const arrOfArticlesToPrint = this.selectedArticles
        .map((item, index) => {
          return Array(this.arrayOfInputFieldValues[index]).fill(item);
        })
        .flat();
      this.templateDataA7 = PrintTemplate.divideArrInSubArr(arrOfArticlesToPrint);
      this.printCommand('A7');
      this.printedCommand();
    },
    async printAll(printType) {
      if (printType === 'A7') {
        if (this.selectedArticles.length > 0) {
          this.modalVisible = true;
          this.inputSets = this.selectedArticles;
        }
      } else {
        this.templateDataA4 = this.selectedArticles;
        this.printCommand(printType);
        this.printedCommand();
      }
    },
    openModal() {
      this.modalOpen = true;
    },
    printSingleArticle(row, type) {
      this.selectedArticles = [];
      this.select(row);
      this.printAll(type);
    },
    printCommand(printType) {
      const stylesHtml =
        printType === 'A7' ? PrintTemplate.getTemplatePrintCss('A7') : PrintTemplate.getTemplatePrintCss('A4');
      setTimeout(() => {}, 2000);
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      setTimeout(() => {
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${printType === 'A7' ? this.$refs.promoTemplateA7.$el.innerHTML : this.$refs.promoTemplateA4.$el.innerHTML}
          </body>
        </html>`);
      }, 1000);
      setTimeout(() => {
        WinPrint.document.close();
      }, 1000);
      setTimeout(() => {
        WinPrint.focus();
      }, 1000);
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 2000);
    },
    printedCommand() {
      Vue.swal({
        title: 'Mark these items printed',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Marked Printed',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDismissed) {
          return null;
        }
        if (result.value) {
          const list = {
            ids: this.selectedArticles.map(item => item.id)
          };

          this.patchOne({
            list,
            router,
            redirectUrl: '/pop'
          });
        }
      });
    },
    applyDate() {
      if (this.startDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.startDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
              this.applyDate();
            } else {
              this.$router.push({ query: { search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },

    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    columnStyle(column) {
      return { width: column.width };
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@media all and (max-width: 425px) {
  .columnsReverse {
    flex-direction: column-reverse;
  }
} ;
</style>
