<template>
  <div class="component-tablebox">
    <!-- table button -->
    <b-row class="table-bottom-wrapper mb-2 mx-0">
      <b-col :cols="12" class="px-0">
        <b-button v-if="showAdd" size="sm" :class="{
          'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': isDarkMode
        }" @click="clickAdd">
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button v-if="showDownload" size="sm" :class="{
          'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': isDarkMode
        }" @click="clickDownload">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
      </b-col>
    </b-row>
    <div class="table-top-wrapper">
      <div v-if="totalCount" class="row-total-count">
        <span>Total Count:</span>
        {{ totalCount }}
      </div>
    </div>

    <div class="box-shadow2 border-top">
      <div :class="{
        'table-row table-row-header': !isDarkMode,
        'table-row-dark dark-div': isDarkMode
      }">
        <div v-for="(column, index) in columns" :key="column.id" :class="{
          ...column.class,
          'table-column': !isDarkMode,
          'table-column-dark': isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
        }" :style="columnStyle(column)">
          {{ column.headerText }}
        </div>
      </div>
      <div :class="{
        'table-row ': !isDarkMode,
        'table-row-dark': isDarkMode
      }" v-for="row in rows" :key="row.id">
        <div v-for="(column, index) in columns" :key="column.textKey" :class="{
          ...column.class,
          'table-column ': !isDarkMode,
          'table-column-dark': isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
        }" :style="columnStyle(column)">
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <span v-if="column.textKey" class="span-text">{{ row[column.textKey] }}</span>
          <div v-if="column.type === 'functions'" class="text-center">
            <b-button-group size="sm">
              <b-button v-if="column.functions.edit && ifAdmin()" class="box-shadow2" size="sm"
                :variant="isDarkMode ? 'outline-light' : 'secondary'" @click="clickEdit(row)">
                <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />View/Edit
              </b-button>
              <b-button v-if="column.functions.delete && ifAdmin()" size="sm" :class="{
                'box-shadow2': !isDarkMode,
                'text-white': isDarkMode
              }" :variant="isDarkMode ? 'outline-warning' : 'warning'" @click="clickDelete(row)">
                <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />Delete
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
      <div v-if="!loading && rows.length === 0"
        :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
        <div :class="{
          'table-column table-column-full text-center': !isDarkMode,
          'table-column-dark table-column-full text-center': isDarkMode
        }">{{ emptyText }}</div>
      </div>
      <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
        <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
          <div :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }">
          </div>
        </div>
      </b-overlay>
    </div>

    <b-row class="table-bottom-wrapper mt-2 mx-0">
      <b-col lg="8" sm="12">
        <b-button v-if="showAdd" size="sm" :class="{
          'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': isDarkMode
        }" @click="clickAdd">
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button v-if="showDownload" size="sm" :class="{
          'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': isDarkMode
        }" @click="clickDownload">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
      </b-col>
      <b-col lg="8" sm="12" v-if="showPagination">
        <b-pagination-nav v-model="currentPage" :link-gen="linkGen" :number-of-pages="totalNumberOfPage" use-router
          no-page-detect align="right" :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"></b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    columns: Array,
    rows: Array,
    pagination: Object,
    baseUrl: String,
    emptyText: String,
    showAdd: Boolean,
    showDownload: Boolean,
    downloadText: String,
    addText: String,
    loading: Boolean,
    showPagination: Boolean
  },
  name: 'StoreBox',
  data() {
    return {};
  },
  computed: {
    ...mapState('auth', { authUser: 'user' }),
    ...mapState('darkMode', ['isDarkMode']),
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    totalCount() {
      if (this.pagination) {
        return this.pagination.total_rows;
      }
      return 0;
    }
  },
  methods: {
    columnStyle(column) {
      return { width: column.width };
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    clickAdd() {
      this.$emit('add', {});
    },
    clickDownload() {
      this.$emit('download', {});
    },
    clickEdit(row) {
      this.$emit('edit', { row });
    },
    clickDelete(row) {
      this.$emit('delete', { row });
    },
    ifAdmin() {
      const newUser = this.authUser.roles.map(role => role.name);
      const admin = newUser.filter(role => role.includes('ADMIN'));
      if (admin.length > 0) {
        return true;
      }
      return false;
    },
    ifFinanceAdmin() {
      const newUser = this.authUser.roles.map(role => role.name);
      const financeAdmin = newUser.filter(role => role.includes('FINANCE_ADMIN'));
      if (financeAdmin.length > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.component-tablebox {
  font-size: 0.9rem;
}

.table-top-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: left;
}

.table-container {
  display: block;
  width: 100%;
}

.row-total-count {
  margin-bottom: 0.5rem;
}

.row-total-count span {
  font-weight: bold;
}

.table-row {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px #d9d9d9;
  transition: 0.5s;
  margin-left: 0;
  margin-right: 0;
}

.table-row:first-of-type {
  border-top: solid 1px #d9d9d9;
  border-left: solid 1px #d9d9d9;
  border-bottom: solid 2px #d9d9d9;
}

.table-column {
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  word-wrap: break-word;
}

.table-columns {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
}

.table-columns .table-column {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}

.table-columns .table-column:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-row:first-of-type .table-column {
  background: #fff;
  color: #000;
  border-color: #d9d9d9;
  font-weight: bold;
}

.table-row:nth-child(odd) .table-column {
  background: #f4f2f1;
}

.table-row:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-column-full {
  width: 100%;
}

.table-column-rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.flex-cell {
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
}

@media all and (max-width: 767px) {
  .table-row {
    border-bottom: solid 2px #d9d9d9;
    border-right: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 50% !important;
    border: 0;
    text-align: left !important;
    background: #fff;
  }

  .table-row .table-column.table-column-first {
    border-bottom: solid 1px #d9d9d9;
    width: 100% !important;
  }

  .table-row .table-column.table-column-last-odd {
    width: 100% !important;
  }
}

@media all and (max-width: 430px) {
  .table-column {
    width: 100%;
  }

  .column .table-column {
    border-bottom: solid 1px;
  }

  .table-column.table-column-first {
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 100% !important;
    border-bottom: 0;
    text-align: left !important;
  }

  .table-row .table-column:last-of-type {
    border-bottom: solid 1px #d9d9d9;
  }

  .table-columns {
    width: 100%;
  }

  .table-columns.table-column {
    border-bottom: solid 1px #d9d9d9;
  }

  .flex-cell {
    width: 100%;
  }
}
</style>
