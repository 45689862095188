var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-tablebox"},[_c('b-row',{staticClass:"table-bottom-wrapper mb-2 mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"cols":12}},[(_vm.showAdd)?_c('b-button',{class:{
        'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
        'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
      },attrs:{"size":"sm"},on:{"click":_vm.clickAdd}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.addText)+" ")],1):_vm._e(),(_vm.showDownload)?_c('b-button',{class:{
        'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
        'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
      },attrs:{"size":"sm"},on:{"click":_vm.clickDownload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadText)+" ")],1):_vm._e()],1)],1),_c('div',{staticClass:"table-top-wrapper"},[(_vm.totalCount)?_c('div',{staticClass:"row-total-count"},[_c('span',[_vm._v("Total Count:")]),_vm._v(" "+_vm._s(_vm.totalCount)+" ")]):_vm._e()]),_c('div',{staticClass:"box-shadow2 border-top"},[_c('div',{class:{
      'table-row table-row-header': !_vm.isDarkMode,
      'table-row-dark dark-div': _vm.isDarkMode
    }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,class:Object.assign({}, column.class,
        {'table-column': !_vm.isDarkMode,
        'table-column-dark': _vm.isDarkMode,
        'table-column-first': index === 0,
        'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[_vm._v(" "+_vm._s(column.headerText)+" ")])}),0),_vm._l((_vm.rows),function(row){return _c('div',{key:row.id,class:{
      'table-row ': !_vm.isDarkMode,
      'table-row-dark': _vm.isDarkMode
    }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.textKey,class:Object.assign({}, column.class,
        {'table-column ': !_vm.isDarkMode,
        'table-column-dark': _vm.isDarkMode,
        'table-column-first': index === 0,
        'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[(column.slotKey)?_vm._t(column.slotKey,null,{"row":row}):_vm._e(),(column.htmlKey)?_c('div',{domProps:{"innerHTML":_vm._s(row[column.htmlKey])}}):_vm._e(),(column.textKey)?_c('span',{staticClass:"span-text"},[_vm._v(_vm._s(row[column.textKey]))]):_vm._e(),(column.type === 'functions')?_c('div',{staticClass:"text-center"},[_c('b-button-group',{attrs:{"size":"sm"}},[(column.functions.edit && _vm.ifAdmin())?_c('b-button',{staticClass:"box-shadow2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":function($event){return _vm.clickEdit(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("View/Edit ")],1):_vm._e(),(column.functions.delete && _vm.ifAdmin())?_c('b-button',{class:{
              'box-shadow2': !_vm.isDarkMode,
              'text-white': _vm.isDarkMode
            },attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning'},on:{"click":function($event){return _vm.clickDelete(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'trash-alt']}}),_vm._v("Delete ")],1):_vm._e()],1)],1):_vm._e()],2)}),0)}),(!_vm.loading && _vm.rows.length === 0)?_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
        'table-column table-column-full text-center': !_vm.isDarkMode,
        'table-column-dark table-column-full text-center': _vm.isDarkMode
      }},[_vm._v(_vm._s(_vm.emptyText))])]):_vm._e(),(_vm.loading)?_c('b-overlay',{attrs:{"show":"","variant":_vm.isDarkMode ? 'outline-light' : 'outline-secondary'}},[_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
          'table-column table-column-full text-center': !_vm.isDarkMode,
          'table-column-dark table-column-full text-center': _vm.isDarkMode
        }})])]):_vm._e()],2),_c('b-row',{staticClass:"table-bottom-wrapper mt-2 mx-0"},[_c('b-col',{attrs:{"lg":"8","sm":"12"}},[(_vm.showAdd)?_c('b-button',{class:{
        'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
        'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
      },attrs:{"size":"sm"},on:{"click":_vm.clickAdd}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.addText)+" ")],1):_vm._e(),(_vm.showDownload)?_c('b-button',{class:{
        'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
        'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
      },attrs:{"size":"sm"},on:{"click":_vm.clickDownload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadText)+" ")],1):_vm._e()],1),(_vm.showPagination)?_c('b-col',{attrs:{"lg":"8","sm":"12"}},[_c('b-pagination-nav',{class:{ '': !_vm.isDarkMode, 'dark-nav': _vm.isDarkMode },attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.totalNumberOfPage,"use-router":"","no-page-detect":"","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }