<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <div v-if="this.user.roles.filter(role => role.name === 'ADMIN' || role.name === 'SUB_ADMIN').length >= 1">
      <b-row class="table-bottom-wrapper mb-2 mx-0">
        <b-col sm="12" lg="12" class="px-0">
          <b-button
            size="sm"
            :class="{
              'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
              'btn btn-primary text-light mb-2 mr-2': isDarkMode
            }"
            @click="onAdd()"
          >
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Create New Form
          </b-button>
          <b-button
            v-if="this.showArchived === false"
            size="sm"
            :class="{
              'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
              'btn btn-primary text-light mb-2 mr-2': isDarkMode
            }"
            @click="showArchivedForms()"
          >
            <font-awesome-icon :icon="['fas', 'filter']" class="mr-1" />Show Archived Forms
          </b-button>
          <b-button
            v-if="this.showArchived === true"
            size="sm"
            :class="{
              'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
              'btn btn-primary text-light mb-2 mr-2': isDarkMode
            }"
            @click="showUnarchivedForms()"
          >
            <font-awesome-icon :icon="['fas', 'filter']" class="mr-1" />Show Unarchived Forms
          </b-button>
          <b-button
            size="sm"
            :class="{
              'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
              'btn btn-primary text-light mb-2 mr-2': isDarkMode
            }"
            @click="donwloadGrnReport()"
          >
            <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Download GRN Report
          </b-button>
          <div v-if="loading">
            <span class="spinner"></span>
          </div>
          <b-form inline class="float-right">
            <b-input-group class="box-shadow2 ml-2 mb-2">
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  id="start-datepicker"
                  placeholder="Start Date"
                  v-b-tooltip.hover
                  title="Only for GRN Report"
                  :min="minDate"
                  :max="maxDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="startDateValue"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-form-input
                id="example-input"
                v-model="startDateValue"
                type="text"
                placeholder="Start Date"
                autocomplete="off"
                :class="{
                  '': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
              ></b-form-input>
            </b-input-group>
            <b-input-group class="box-shadow2 ml-2 mb-2">
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  id="end-datepicker"
                  placeholder="End Date"
                  v-b-tooltip.hover
                  title="Only For GRN report"
                  :min="minDate"
                  :max="maxDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="endDateValue"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-form-input
                id="example-input"
                v-model="endDateValue"
                type="text"
                placeholder="End Date"
                autocomplete="off"
                :class="{
                  '': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
              ></b-form-input>
            </b-input-group>
            <b-button
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2': isDarkMode
              }"
              @click="applyDate"
              >Apply</b-button
            >
            <b-button
              :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
              class="box-shadow2 ml-2 mb-2"
              @click="clearDate"
              >Clear</b-button
            >
          </b-form>

          <div v-if="this.showArchived === false" class="page-title text-center mt-4">Active forms </div>
          <div v-if="this.showArchived === true" class="page-title text-center mt-4">Inactive forms </div>
        </b-col>
      </b-row>
    </div>
    <b-container>
      <b-overlay v-if="formList.length === 0" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
        <div class="table-row-empty"> </div>
      </b-overlay>
      <b-row v-if="formList.length !== 0">
        <b-col lg="3" md="6" sm="12" v-for="(form, index) in formList" :key="index">
          <b-row>
            <b-col lg="12" sm="12">
              <div :class="{ tile: !isDarkMode, 'dark-div tile-dark rounded box-shadow2': isDarkMode }">
                <div
                  :class="{ 'tile-heading': !isDarkMode, 'tile-heading-dark text-light': isDarkMode }"
                  @click="onOpen({ form })"
                  >{{ form.name }}</div
                ><br />
                <div v-if="ifAdmin()">
                  <b-button
                    v-if="form.archived === false"
                    size="sm"
                    :variant="isDarkMode ? 'outline-success' : 'success'"
                    @click="onDelete({ form })"
                    v-b-tooltip.hover
                    title="Archived this form"
                  >
                    <font-awesome-icon :icon="['fas', 'download']" class="archive" />
                  </b-button>
                </div>
                <div v-if="ifAdmin()">
                  <b-button
                    v-if="form.archived === true"
                    size="sm"
                    :variant="isDarkMode ? 'outline-success' : 'success'"
                    @click="onRevert({ form })"
                    v-b-tooltip.hover
                    title="Unarchived this form"
                  >
                    <font-awesome-icon :icon="['fas', 'upload']" class="archive" />
                  </b-button>
                  <b-button
                    v-if="form.archived === true"
                    size="sm"
                    class="ml-2"
                    :variant="isDarkMode ? 'outline-danger' : 'danger'"
                    @click="onDeleteFullForm({ form })"
                    v-b-tooltip.hover
                    title="Delete this form"
                  >
                    <font-awesome-icon :icon="['fas', 'trash-alt']" class="archive" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'FormList',
  metaInfo() {
    return {
      title: 'Forms',
      meta: [
        {
          name: 'description',
          content: 'View Forms'
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listUnarchivedForms({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listUnarchivedForms({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listUnarchivedForms({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Forms',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Form Name',
          textKey: 'name',
          class: { 'text-center': true },
          width: '50%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '30%',
          functions: {
            edit: false,
            delete: true,
            open: true
          }
        }
      ],
      startDateValue: moment().format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD')
    };
  },
  computed: {
    ...mapState('form', ['formList', 'baseUrl', 'loading', 'showArchived', 'grnReportData']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    grnReportData(newValue) {
      if (newValue?.length > 0) {
        let csv = '';
        csv += '\n';
        csv += 'Article Code, Store Id, Grn Number, Grn Entry Time,Grn Entry Date, Grn Year,  Po OR Sto\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Grn Report.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('form', [
      'listUnarchivedForms',
      'listArchivedForms',
      'deleteOne',
      'getOne',
      'deleteFullForm',
      'getGrnReportData'
    ]),
    onView({ form }) {
      router.push(`/form/${form.id}`);
    },
    onAdd() {
      router.push('/form/new');
    },
    onOpen({ form }) {
      router.push(`/form/operations?formTarget=${form._id}&formName=${form.name}`);
    },
    onEdit({ row }) {
      router.push(`/form/${row._id}`);
    },
    showArchivedForms() {
      this.listArchivedForms();
    },
    showUnarchivedForms() {
      this.listUnarchivedForms();
    },
    donwloadGrnReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.getGrnReportData({
          query: {
            page: 1,
            page_size: 99999,
            ...this.$route.query
          },
          router
        });
      } else {
        this.getGrnReportData({
          query: {
            page: 1,
            page_size: 99999,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          },
          router
        });
      }
    },
    applyDate() {
      if (this.startDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD')
          }
        });
      }
      if (this.endDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    onDelete({ form }) {
      Vue.swal({
        title: 'Are you sure you want to archive this form?',
        // text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, archive it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            type: 'form',
            id: form._id /* eslint no-underscore-dangle: 0 */
          });
        }
      });
    },
    onDeleteFullForm({ form }) {
      Vue.swal({
        title: 'Are you sure you want to delete this form?',
        // text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteFullForm({
            type: 'form',
            id: form._id /* eslint no-underscore-dangle: 0 */
          });
        }
      });
    },
    onRevert({ form }) {
      Vue.swal({
        title: 'Are you sure you want to unarchive this form?',
        // text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unarchive it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            type: 'form',
            id: form._id /* eslint no-underscore-dangle: 0 */
          });
        }
      });
    },
    ifAdmin() {
      return this.user.roles.filter(role => role.name === 'ADMIN' || role.name === 'SUB_ADMIN').length >= 1;
    }
  }
};
</script>

<style scoped>
.tile {
  height: auto;
  margin: 10px;
  padding: 10px;
  display: block;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
}

.tile-dark {
  height: auto;
  margin: 10px;
  padding: 10px;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tile-heading {
  color: rgb(71, 167, 69);
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tile-heading-dark {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tile-heading:hover {
  cursor: pointer;
  transition: 0.5s;
  color: rgb(37, 105, 36);
  white-space: normal;
}

.tile-heading-dark:hover {
  cursor: pointer;
  transition: 0.5s;
  white-space: normal;
}

.archive {
  position: center;
  align-self: center;
  /* margin: 5px; */
}

.form_heading {
  display: flex;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
}
</style>
