<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <dynamic-table-box
      :columns="columns"
      :loading="loading"
      :pagination="this.formData.pagination"
      :showPagination="true"
      :grnReportRequire="ifVehicleEntryTime"
      :downloadingReport="reportDownloading"
      :baseUrl="baseUrl"
      :rows="this.alteredRows"
      :showAdd="true"
      :addText="addText"
      :emptyText="emptyText"
      @add="onAdd"
      @download="onDownload"
      @download-images="downloadImages"
      :searchItems="
        filterOptions.concat(
          this.formData.columnNames.map(name =>
            name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
              return str.toUpperCase();
            })
          )
        )
      "
      :showToolbar="true"
      @show-upload="onShowUpload"
      @sampledownload="createCsv"
      @gapReportDownload="donwloadGapReport"
    ></dynamic-table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Vue from 'vue';
import csv from 'csvtojson';
import moment from 'moment';
import router from '@/router';
import axios from 'axios';
import configService from '@/services/configService';
import _ from 'lodash';
import DynamicTableBox from '../../components/DynamicTableBox.vue';

export default {
  name: 'FormData',
  components: {
    DynamicTableBox
  },
  async mounted() {
    this.listStore({ router });
    await this.getInputFields({ id: this.$route.query.formTarget });
    if (_.isEmpty(this.$route.query)) {
      this.listFormData({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listFormData({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listFormData({ query: to.query });
    next();
  },
  data() {
    return {
      columns: [],
      filterOptions: ['Select Filter'],
      labels: '',
      models: []
    };
  },
  computed: {
    addText() {
      if (this.inputFields) {
        return `Add new ${this.inputFields.name}`;
      }
      return `Add new ${this.$route.query.formName}`;
    },
    ifVehicleEntryTime() {
      if (this.inputFields?.name == 'Vehicle Entry Time ') {
        return true;
      }
      return false;
    },
    emptyText() {
      if (this.inputFields) {
        return `No ${this.inputFields.name} found.`;
      }
      return `No ${this.$route.query.formName} found.`;
    },
    title() {
      if (this.inputFields) {
        return this.inputFields.name;
      }
      return this.$route.query.formName;
    },
    alteredRows() {
      if (this.formData.columnNames.length > 0) {
        const foundFileField = this.inputFields.config.filter(
          select => select.type === 'images' || select.type === 'file'
        );
        const fileFieldName = foundFileField.map(item => item.model);
        const alteredRows = this.formData.rows.map(item => {
          return {
            ...item,
            ...Object.assign(
              {},
              ...fileFieldName.map(i => ({
                [i]: Array.isArray(item[i])
                  ? item[i].length <= 1
                    ? item[i].map(j => j.replace('?', '%3F')).join(',')
                    : `No. of Images = ${item[i].length}`
                  : item[i].replace('?', '%3F')
              }))
            )
          };
        });
        return alteredRows;
      }
      return [];
    },
    ...mapState('form', [
      'loading',
      'formData',
      'inputFields',
      'baseUrl',
      'formDataDownload',
      'grnGapReportData',
      'reportDownloading'
    ]),
    ...mapState('store', ['storeList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('form', [
      'getOne',
      'postOne',
      'patchOne',
      'listFormData',
      'downloadFormData',
      'getInputFields',
      'upload',
      'getGrnGapReportData'
    ]),
    ...mapActions('store', ['listStore']),
    donwloadGapReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.getGrnGapReportData({
          query: {
            page: 1,
            page_size: 99999,
            ...this.$route.query
          },
          router
        });
      } else {
        this.getGrnGapReportData({
          query: {
            ...this.$route.query,
            page: 1,
            page_size: 99999,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          },
          router
        });
      }
    },
    downloadImages({ row }) {
      const images = [];
      const form = this.formData.rows.filter(item => item._id === row._id).map(i => i)[0];
      const findImagesObject = Object.values(form)
        .filter(value => typeof value === 'object')
        .flat(1);
      const filterImagesObject = findImagesObject.filter(item => item.includes('amazonaws'));
      const findImagesString = Object.values(form).filter(
        value => typeof value === 'string' && value.includes('amazonaws')
      );
      let storeID = '';
      storeID = row.storeID ? row.storeID : row.storeid ? row.storeid : row.storeId ? row.storeId : '';
      // const FindImageFields = Object.values(row).filter(value => typeof value === 'string' && value.includes('https'));
      const FindImageFields = [...filterImagesObject, ...findImagesString];

      if (FindImageFields.length > 0) {
        FindImageFields.forEach(image => {
          images.push(image);
        });
      } else {
        Vue.swal({
          title: 'No images found',
          text: 'No images found in this record',
          type: 'warning',
          confirmButtonText: 'OK',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      }
      axios
        .post(`${configService.get('apiUrl')}/form/download-photos`, { images }, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${storeID}.zip`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(e => {
          throw e;
        });
    },
    onAdd() {
      const { formTarget } = this.$route.query;
      router.push(`/form/fill/${formTarget}`);
    },
    onEdit({ form }) {
      this.patchOne({
        type: 'form',
        id: this.id,
        form,
        router,
        redirectUrl: '/form'
      });
    },
    createCsv() {
      const csv = `${this.labels}`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Sample.csv';
      anchor.click();
    },
    async onShowUpload() {
      await Vue.swal({
        title: 'Upload data..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  const values = item.reduce(
                    (acc, curr, idx) => ({
                      ...acc,
                      [this.models[idx]]: curr.charAt(0).toUpperCase() + curr.slice(1)
                    }),
                    {}
                  );
                  return values;
                });
                const foundOptionField = this.inputFields.config.filter(select => select.type === 'select');
                if (foundOptionField.length > 0) {
                  const getKeys = foundOptionField.map(i => i.model);
                  const getLabel = foundOptionField.map(i => i.label);
                  const getValues = foundOptionField.map(i => i.options);
                  const DataInInputs = inputs.map(i => {
                    // match value with getKeys and getValues
                    for (let j = 0; j < getKeys.length; j++) {
                      if (i[getKeys[j]]) {
                        if (getValues[j].length > 0) {
                          const foundValue = getValues[j].find(value => value === i[getKeys[j]]);
                          if (foundValue !== undefined) {
                            i[getKeys[j]] = foundValue;
                          }
                          if (foundValue === undefined) {
                            Vue.swal({
                              title: 'Error',
                              text: `There is invalid value in ${getLabel[j]}`,
                              type: 'error',
                              confirmButtonText: 'OK',
                              background: this.isDarkMode ? '#464d54' : '#ffffff',
                              customClass: {
                                title: this.isDarkMode ? 'text-light' : 'text-dark',
                                content: this.isDarkMode ? 'text-light' : 'text-dark'
                              }
                            });
                            return null;
                          }
                        }
                      }
                    }
                  });
                  const filteredData = DataInInputs.includes(null);
                  if (filteredData === true) {
                    return DataInInputs;
                  }
                }

                const foundImageField = this.inputFields.config.filter(select => select.type === 'file');
                if (foundImageField.length > 0) {
                  Vue.swal({
                    title: 'Not Allowed',
                    text: `You can not upload images from CRM, You have to use Disha App to upload images`,
                    type: 'warning',
                    confirmButtonText: 'OK',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }

                const foundDateField = this.inputFields.config.filter(select => select.type === 'date');
                if (foundDateField.length > 0) {
                  const getKeys = foundDateField.map(i => i.model);
                  const getLabel = foundDateField.map(i => i.label);
                  const DataInInputs = inputs.map(i => {
                    for (let j = 0; j < getKeys.length; j++) {
                      if (i[getKeys[j]]) {
                        const date = i[getKeys[j]];
                        const dateRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;
                        if (dateRegex.test(date)) {
                          const dateArray = date.split('-');
                          const day = dateArray[0];
                          const month = dateArray[1];
                          const year = dateArray[2];
                          const newDate = `${year}-${month}-${day}`;
                          i[getKeys[j]] = newDate;
                        } else {
                          Vue.swal({
                            title: 'Error',
                            text: `There is invalid value in ${getLabel[j]}`,
                            type: 'error',
                            confirmButtonText: 'OK',
                            background: this.isDarkMode ? '#464d54' : '#ffffff',
                            customClass: {
                              title: this.isDarkMode ? 'text-light' : 'text-dark',
                              content: this.isDarkMode ? 'text-light' : 'text-dark'
                            }
                          });
                          return null;
                        }
                      }
                    }
                  });
                  const filteredData = DataInInputs.includes(null);
                  if (filteredData === true) {
                    return DataInInputs;
                  }
                }

                const foundTimeField = this.inputFields.config.filter(select => select.type === 'time');
                if (foundTimeField.length > 0) {
                  const getKeys = foundTimeField.map(i => i.model);
                  const getLabel = foundTimeField.map(i => i.label);
                  const DataInInputs = inputs.map(i => {
                    for (let j = 0; j < getKeys.length; j++) {
                      if (i[getKeys[j]]) {
                        const time = i[getKeys[j]];
                        const timeRegex = /^\d{1,2}:\d{1,2} (AM|PM)$/;
                        const timeRegex2 = /^\d{1,2}:\d{1,2} (am|pm)$/;
                        const timeRegex3 = /^\d{1,2}:\d{1,2} (aM|Am|pM|Pm)$/;
                        const timeRegex4 = /^\d{1,2}:\d{1,2}:\d{1,2}$/;
                        const timeRegex5 = /^\d{1,2}:\d{1,2}$/;
                        const timeRegex6 = /^\d{1,2}:\d{1,2}:\d{1,2} (AM|PM)$/;
                        const timeRegex7 = /^\d{1,2}:\d{1,2}:\d{1,2} (am|pm)$/;
                        const timeRegex8 = /^\d{1,2}:\d{1,2}:\d{1,2} (aM|Am|pM|Pm)$/;

                        if (timeRegex.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1].split(' ')[0];
                          const second = '00';
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex2.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1].split(' ')[0];
                          const second = '00';
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex3.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1].split(' ')[0];
                          const second = '00';
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex4.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1];
                          const second = timeArray[2];
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex5.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1];
                          const second = '00';
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex6.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1];
                          const second = timeArray[2].split(' ')[0];
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex7.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1];
                          const second = timeArray[2].split(' ')[0];
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else if (timeRegex8.test(time)) {
                          const timeArray = time.split(':');
                          const hour = timeArray[0];
                          const minute = timeArray[1];
                          const second = timeArray[2].split(' ')[0];
                          const date = new Date();
                          const newTime = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            hour,
                            minute,
                            second
                          );
                          i[getKeys[j]] = newTime;
                        } else {
                          Vue.swal({
                            title: 'Error',
                            text: `There is invalid value in ${getLabel[j]}`,
                            type: 'error',
                            confirmButtonText: 'OK',
                            background: this.isDarkMode ? '#464d54' : '#ffffff',
                            customClass: {
                              title: this.isDarkMode ? 'text-light' : 'text-dark',
                              content: this.isDarkMode ? 'text-light' : 'text-dark'
                            }
                          });
                          return null;
                        }
                      }
                    }
                  });
                  const filteredData = DataInInputs.includes(null);
                  if (filteredData === true) {
                    return DataInInputs;
                  }
                }

                const storeInFile = inputs.map(
                  item => item.storeID || item.storeId || item.store_id || item.storeid || item.storeCode
                );
                if (storeInFile[0].length !== 4) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store Id ${
                      storeInFile[0].charAt(0).toUpperCase() + storeInFile[0].slice(1)
                    } must be 4 digits`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                const inUppercase = storeInFile[0].toUpperCase();
                const matchWithDb = this.newStoreList.filter(item => item.storeid === inUppercase);
                if (matchWithDb.length === 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store Id ${
                      storeInFile[0].charAt(0).toUpperCase() + storeInFile[0].slice(1)
                    } is not found in the database! Please enter correct store id.`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                const uploadData = {
                  formTarget: this.$route.query.formTarget,
                  data: inputs
                };
                this.upload({ router, uploadData });
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    onDownload() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadFormData({ query: { ...this.$route.query, page_size: 9999, page: 1 } });
      } else {
        this.downloadFormData({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    }
  },

  watch: {
    grnGapReportData(newValue) {
      if (newValue?.length > 0) {
        let csv = '';
        csv += '\n';
        csv += ' Store Id, Submitted By, Grn Entry Time,Vehicle Entry Time,Gap Time,Created At\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Grn Gap Report.csv';
        anchor.click();
      }
    },
    formDataDownload(_newValue) {
      const labels = this.inputFields.config.map(item => item.model.replaceAll(',', '')).join(',');
      const header = [...this.inputFields.config.map(item => item.model), 'createdAt', 'submittedBy'];
      const type = this.inputFields.config.map(item => item.type);
      if (_newValue && _newValue.rows.length) {
        let csv = `${labels},Created At,Submitted By\n`;
        _newValue.rows.forEach(row => {
          header.forEach(key => {
            if (type[header.indexOf(key)] === 'date') {
              csv += `"${moment(row[key]).format('DD-MM-YYYY')}",`;
            } else if (type[header.indexOf(key)] === 'time') {
              csv += `"${moment(row[key]).format('hh:mm  A')}",`;
            } else if (type[header.indexOf(key)] === 'file') {
              if (typeof row[key] === 'string') {
                csv += `"${row[key].replace('?', '%3F')}",`;
              }
              if (typeof row[key] === 'object') {
                csv += `"${row[key].join(',').replace('?', '%3F')}",`;
              }
            } else if (type[header.indexOf(key)] === 'images') {
              if (typeof row[key] === 'string') {
                csv += `"${row[key].replace('?', '%3F')}",`;
              }
              if (typeof row[key] === 'object') {
                csv += `"${row[key].join(',').replace('?', '%3F')}",`;
              }
            } else if (row.hasOwnProperty(key)) {
              csv += `"${row[key]}",`;
            } else {
              csv += `${'-'},`;
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Download.csv';
        anchor.click();
      }
    },
    inputFields(newValue) {
      this.columns = [];
      if (newValue && newValue.config) {
        this.labels = newValue.config.map(item => item.label);
        this.labels += '';
        this.models = newValue.config.map(item => item.model);
        const findImages = newValue.config.filter(i => i.type === 'file').length > 0;
        const additionalWidth = findImages ? 3 : 2;
        const width = `${((100 / (newValue.config.length + additionalWidth)) * 100) / 100}%`;
        [
          ...newValue.config,
          { model: 'createdAt', label: 'Created At' },
          { model: 'submittedBy', label: 'Submitted By' }
        ].forEach(element => {
          const column = {
            type: 'string',
            // eslint-disable-next-line func-names
            headerText: element.label,
            textKey: element.model,
            class: { 'text-center': true },
            width
          };
          this.columns.push(column);
        });
        if (findImages) {
          const column2 = {
            type: 'functions',
            headerText: 'Functions',
            class: { 'text-center': true },
            width,
            functions: {
              downloadImages: true
            }
          };
          this.columns.push(column2);
        }
      }
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
    }
  }
};
</script>
